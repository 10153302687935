import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ActionBar,
  Main,
  ModalDelete,
  PageLoader,
} from "components";
import { Field, reduxForm } from "redux-form";
import { Input } from "components";
import {
  updateCourseModule,
  getCourseModule,
  deleteCourseModule,
} from "actions/course_module";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import CourseModuleWidgets from "./components/CourseModuleWidgets";

const InputField = (props) => <Input {...props} />;

const validate = (values) => {
  const errors = {};
  ["title"].forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  return errors;
};

const EditCourseModule = ({
  course_module,
  match: { params },
  pristine,
  history,
  getCourseModule,
  updateCourseModule,
  handleSubmit,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getCourseModule(params.course_module_id);
  }, [params.course_module_id]);

  const onSubmit = (values) =>
    updateCourseModule(params.course_module_id, values);

  const handleDelete = async () => {
    try {
      await deleteCourseModule(params.course_module_id);
      history.push(`/courses/${params.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = course_module;

  if (status === "invalid" || status === "loading")
    return <PageLoader />;

  if (status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[
        { name: "Kurs", href: `/courses/${params.id}` },
        { name: data?.title },
      ]}
      title={data?.title}>
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.title}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form noValidate>
                <Field
                  label="Tytuł"
                  name="title"
                  component={InputField}
                />
                <Field
                  label="Pozycja"
                  name="position"
                  type="number"
                  component={InputField}
                />
              </form>
            </div>
          </div>
        </div>
        <div className="column-half">
          <CourseModuleWidgets />
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path={`/courses/${params.id}`}
      />
    </Main>
  );
};

export default connect(
  ({ course_module }) => ({
    course_module,
    initialValues: {
      title: course_module?.data?.title,
      position: course_module?.data?.position,
    },
  }),
  (dispatch) => ({
    updateCourseModule: (data, id) =>
      dispatch(updateCourseModule(data, id)),
    getCourseModule: (id) => dispatch(getCourseModule(id)),
  })
)(
  reduxForm({
    form: "update_curse_module",
    enableReinitialize: true,
    validate,
  })(EditCourseModule)
);
