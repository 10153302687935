import React from 'react'
import { Link } from 'react-router-dom';
import { renderIcons } from '../../icons'

const SidebarItem = ({
  name,
  path,
  icon,
  history,
}) => {
  const isActive = history.location.pathname.includes(path);


  return (
    <li
      className={`nav-item ${isActive ? "active" : ""}`}>
      <Link to={path} className="nav-item__trigger">
        <div className="nav-item__icon">{renderIcons(icon)}</div>
        <p className="nav-item__title">{name}</p>
      </Link>
    </li>
  )
}

export default SidebarItem
