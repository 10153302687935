import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  Search,
  Pagination,
  Main,
  PageLoader,
  Button,
} from "components";
import { getOwners } from "actions/owners";
import InviteOwner from "./components/InviteOwner";
import moment from "moment";

const OwnersList = ({
  location: { search },
  history,
  owners,
  getOwners,
}) => {
  const [
    is_open_invite_manager_modal,
    setOpenInviteOwnerModal,
  ] = useState(false);

  const {
    page,
    per_page,
    query: default_query,
  } = queryString.parse(search);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getOwners({ page, per_page, query });
  }, [page, per_page]);

  if (
    owners?.status === "invalid" ||
    owners?.status === "loading"
  )
    return <PageLoader />;

  if (owners?.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = owners;

  return (
    <Main
      breadcrumbs={[{ name: "Reprezantanci jednostek" }]}
      title="Reprezantanci jednostek">
      <div className="dashboard">
        {is_open_invite_manager_modal && (
          <InviteOwner
            handleClose={() =>
              setOpenInviteOwnerModal(false)
            }
          />
        )}
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Reprezantanci jednostek
          </h2>
          <Button
            type="add"
            text="Zaproś"
            onClick={() => setOpenInviteOwnerModal(true)}
          />
          <Button
            style={{ marginLeft: 15 }}
            type="add"
            text="Dodaj"
            onClick={() => history.push("/owners/new")}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getOwners({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Imię i nazwisko</th>
                  <th>Adres e-mail</th>
                  <th>Telefon</th>
                  <th>Organizacja</th>
                  <th>Data utworzenia</th>
                  <th>Konto aktywne</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(
                  ({
                    id,
                    full_name,
                    email,
                    phone,
                    organization,
                    created_at,
                    is_active,
                  }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        history.push(`/owners/${id}`)
                      }>
                      <td>{full_name}</td>
                      <td>{email}</td>
                      <td>{phone || "-"}</td>
                      <td>{organization || "-"}</td>

                      <td>
                        {created_at
                          ? moment(created_at).format(
                              "DD.MM.YYYY"
                            )
                          : "brak"}
                      </td>
                      <td>{is_active ? "Tak" : "Nie"}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak reprezentantów jednostki</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ owners }) => ({ owners }),
  (dispatch) => ({
    getOwners: (data) => dispatch(getOwners(data)),
  })
)(OwnersList);
