import React, { useState } from "react";
import { Button, ImageUploader, Modal } from "components";
import { uploadCsv } from "actions/organizations";

const UploadModal = ({ handleClose }) => {
  const [file, setFile] = useState(null);
  const handleUpload = async () => {
    await uploadCsv(file);
    setFile(null);
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      title="Prześlij plik CSV"
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Wyślij"
            disabled={!file}
            onClick={handleUpload}
          />
        </>
      }>
      <ImageUploader
        accept=".csv"
        handleFile={(el) => setFile(el)}
      />
    </Modal>
  );
};

export default UploadModal;
