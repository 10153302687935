import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";

const getNav = (user_type) => [
  {
    name: "ustawienia",
    items: [
      {
        name: "Strony statyczne",
        path: "/pages",
        icon: "static",
      },
    ],
  },
  {
    name: "Platforma",
    items: [
      user_type === "Admin" && {
        name: "Brokerzy",
        path: "/brokers",
        icon: "users",
      },
      {
        name: "Reprezentanci jednostek",
        path: "/owners",
        icon: "users",
      },
      {
        name: "Organizacje",
        path: "/organizations",
        icon: "offers",
      },
      {
        name: "Baza wiedzy",
        path: "/courses",
        icon: "events",
      },
    ].filter(Boolean),
  },
];

class Sidebar extends Component {
  render() {
    const { current_user, history } = this.props;
    const nav = getNav(current_user.type);
    return (
      <aside className="sidebar">
        <nav>
          {nav.map(({ name, items }) => (
            <React.Fragment key={name}>
              <p className="nav-category">{name}</p>
              <ul>
                {items.map(({ name, path, icon }, id) => (
                  <SidebarItem
                    key={id}
                    name={name}
                    path={path}
                    icon={icon}
                    history={history}
                  />
                ))}
              </ul>
            </React.Fragment>
          ))}
        </nav>
      </aside>
    );
  }
}
const mapStateToProps = ({ current_user }) => ({
  current_user,
});

export default connect(mapStateToProps)(
  withRouter(Sidebar)
);
