import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastr } from "react-redux-toastr";
import current_user from "./current_user";

import brokers from "./brokers";
import broker from "./broker";

import owners from "./owners";
import owner from "./owner";

import pages from "./pages";
import page from "./page";

import courses from "./courses";
import course from "./course";

import course_module from "./course_module";

import organizations from "./organizations";
import organization from "./organization";

export default combineReducers({
  form,
  toastr,
  current_user,
  brokers,
  broker,
  courses,
  course,
  course_module,
  owners,
  owner,
  pages,
  page,
  organizations,
  organization,
});
