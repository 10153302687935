import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const inviteBroker = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.post(`/admin_brokers/invite`, values);
      toastr.success("Sukces", "Broker został zaproszony");
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error(
        "Błąd",
        error?.response?.data?.message || "Wystąpił błąd"
      );
      reject();
    }
  });
