const initialState = {
  status: "invalid",
};

export default (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "OWNERS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "OWNERS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: payload.data,
        meta: payload.meta,
      };
    case "OWNERS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
