import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  ActionBar,
  Button,
  Main,
  ModalDelete,
  PageLoader,
} from "components";
import { Field, reduxForm } from "redux-form";
import { Input } from "components";
import {
  updateBroker,
  getBroker,
  deleteBroker,
  createBrokerOrganization,
  deleteBrokerOrganization,
  createBrokerOwner,
  deleteBrokerOwner,
} from "actions/brokers";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import AssignOrganization from "./AssignOrganization";
import AssignOwner from "./AssignOwner";

const InputField = (props) => <Input {...props} />;

const required_fields = ["first_name", "last_name"];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  if (!values.email) {
    errors.email = "Wymagane";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    )
  ) {
    errors.email = "Niepoprawny adres email";
  }

  return errors;
};

const UpdateBroker = ({
  broker,
  match: {
    params: { id },
  },
  change,
  pristine,
  history,
  getBroker,
  updateBroker,
  createBrokerOrganization,
  deleteBrokerOrganization,
  createBrokerOwner,
  deleteBrokerOwner,
  handleSubmit,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  const [
    is_open_add_organization_modal,
    handleOpenAddOrganizationModal,
  ] = useState(false);

  const [
    delete_organization_modal_data,
    setDeleteOrganizationModalData,
  ] = useState(null);

  const [
    is_open_add_owner_modal,
    handleOpenAddOwnerModal,
  ] = useState(false);

  const [
    delete_owner_modal_data,
    setDeleteOwnerModalData,
  ] = useState(null);

  useEffect(() => {
    getBroker(id);
  }, [id]);

  const onSubmit = (values) =>
    updateBroker(id, values).then(() =>
      change("password", "")
    );

  const handleDelete = async () => {
    try {
      await deleteBroker(id);
      history.push("/brokers");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBrokerOrganization = async () => {
    try {
      await deleteBrokerOrganization({
        broker_organization_id:
          delete_organization_modal_data.id,
        broker_id: id,
      });
      setDeleteOrganizationModalData(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteBrokerOwner = async () => {
    try {
      await deleteBrokerOwner({
        broker_owner_id: delete_owner_modal_data.id,
        broker_id: id,
      });
      setDeleteOwnerModalData(null);
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = broker;

  if (status === "invalid" || status === "loading")
    return <PageLoader />;

  if (status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[
        {
          name: "Brokerzy",
          href: "/brokers",
        },
        { name: data?.full_name },
      ]}
      title={data?.full_name}>
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.full_name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      {delete_organization_modal_data?.id && (
        <ModalDelete
          name={delete_organization_modal_data?.name}
          handleClose={() =>
            setDeleteOrganizationModalData(null)
          }
          handleDelete={handleDeleteBrokerOrganization}
        />
      )}
      {delete_owner_modal_data?.id && (
        <ModalDelete
          name={delete_owner_modal_data?.full_name}
          handleClose={() => setDeleteOwnerModalData(null)}
          handleDelete={handleDeleteBrokerOwner}
        />
      )}
      {is_open_add_organization_modal && (
        <AssignOrganization
          handleClose={() =>
            handleOpenAddOrganizationModal(false)
          }
          handleConfirm={async (organization_id) => {
            await createBrokerOrganization({
              organization_id,
              broker_id: id,
            });
            handleOpenAddOrganizationModal(false);
          }}
        />
      )}
      {is_open_add_owner_modal && (
        <AssignOwner
          handleClose={() => handleOpenAddOwnerModal(false)}
          handleConfirm={async (owner_id) => {
            await createBrokerOwner({
              owner_id,
              broker_id: id,
            });
            handleOpenAddOwnerModal(false);
          }}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Field
                    label="Imie"
                    name="first_name"
                    component={InputField}
                  />
                  <Field
                    label="Nazwisko"
                    name="last_name"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Adres e-mail"
                    name="email"
                    component={InputField}
                  />
                  <Field
                    label="Telefon"
                    name="phone"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Zmień hasło"
                    name="password"
                    component={InputField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Organizacje
              </h2>
              <Button
                type="add"
                text="Dodaj"
                onClick={() =>
                  handleOpenAddOrganizationModal(true)
                }
              />
            </header>
            {data?.broker_organizations?.length > 0 && (
              <table className="dashboard__table">
                <tr>
                  <th>ID</th>
                  <th>Nazwa</th>
                  <th>Miasto</th>
                  <th>Publiczny?</th>
                  <th>Działania</th>
                </tr>
                {data.broker_organizations.map(
                  ({
                    id,
                    organization: {
                      id: organization_id,
                      name,
                      is_public,
                      city,
                    },
                  }) => (
                    <tr key={id}>
                      <td>
                        <Link
                          to={`/organizations/${organization_id}`}>
                          {organization_id}
                        </Link>
                      </td>
                      <td>{name}</td>
                      <td>{city || "-"}</td>
                      <td>{is_public ? "Tak" : "Nie"}</td>
                      <td width="100">
                        <Button
                          type="delete-bin"
                          onClick={() =>
                            setDeleteOrganizationModalData({
                              id,
                              name,
                            })
                          }
                        />
                      </td>
                    </tr>
                  )
                )}
              </table>
            )}
          </div>
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Reprezentanci jednostek
              </h2>
              <Button
                type="add"
                text="Dodaj"
                onClick={() =>
                  handleOpenAddOwnerModal(true)
                }
              />
            </header>
            {data?.broker_owners?.length > 0 && (
              <table className="dashboard__table">
                <tr>
                  <th>ID</th>
                  <th>Imię i nazwisko</th>
                  <th>Aktywny?</th>
                  <th>Działania</th>
                </tr>
                {data.broker_owners.map(
                  ({
                    id,
                    owner: {
                      id: owner_id,
                      full_name,
                      is_active,
                    },
                  }) => (
                    <tr key={id}>
                      <td>
                        <Link to={`/owners/${owner_id}`}>
                          {owner_id}
                        </Link>
                      </td>
                      <td>{full_name}</td>
                      <td>{is_active ? "Tak" : "Nie"}</td>
                      <td width="100">
                        <Button
                          type="delete-bin"
                          onClick={() =>
                            setDeleteOwnerModalData({
                              id,
                              full_name,
                            })
                          }
                        />
                      </td>
                    </tr>
                  )
                )}
              </table>
            )}
          </div>
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/brokers"
      />
    </Main>
  );
};

export default connect(
  ({ broker }) => ({
    broker,
    initialValues: {
      first_name: broker?.data?.first_name,
      last_name: broker?.data?.last_name,
      email: broker?.data?.email,
      phone: broker?.data?.phone,
    },
  }),
  (dispatch) => ({
    updateBroker: (data, id) =>
      dispatch(updateBroker(data, id)),
    getBroker: (id) => dispatch(getBroker(id)),
    createBrokerOrganization: (data) =>
      dispatch(createBrokerOrganization(data)),
    deleteBrokerOrganization: (data) =>
      dispatch(deleteBrokerOrganization(data)),
    createBrokerOwner: (data) =>
      dispatch(createBrokerOwner(data)),
    deleteBrokerOwner: (data) =>
      dispatch(deleteBrokerOwner(data)),
  })
)(
  reduxForm({
    form: "broker_edit",
    enableReinitialize: true,
    validate,
  })(UpdateBroker)
);
