import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getOwners = ({
  page = 1,
  per_page = 10,
  loading = true,
  query,
}) => async (dispatch) => {
  try {
    loading && dispatch({ type: "OWNERS_LOADING" });

    const { data, meta } = await client.get(
      `/owners?sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&search=${query}` : ""
      }`
    );
    dispatch({
      type: "OWNERS_SUCCESS",
      payload: { data, meta },
    });
  } catch (error) {
    dispatch({ type: "OWNERS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const getOwner = (id) => async (dispatch) => {
  try {
    dispatch({ type: "OWNER_LOADING" });
    const { data } = await client.get(`/owners/${id}`);

    dispatch({
      type: "OWNER_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "OWNER_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createOwner = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post("/owners", values);
      toastr.success(
        "Sukces",
        "Reprezentant jednostki zostal dodany"
      );
      resolve(data.id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const updateOwner = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(
        `/owners/${id}`,
        values
      );
      toastr.success(
        "Sukces",
        "Reprezentant jednostki zostal zaktualizowany"
      );
      dispatch({
        type: "OWNER_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteOwner = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/owners/${id}`);
      toastr.success(
        "Sukces",
        "Reprezentant jednostki został usunięty"
      );
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd.");
      reject(error);
    }
  });

export const inviteOwner = (email) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        current_user: { type, id },
      } = getState();
      if (["Admin", "Broker"].includes(type)) {
        if (type === "Admin") {
          await client.post(`/admin_owners/invite`, {
            admin_id: id,
            email,
          });
        }
        if (type === "Broker") {
          await client.post(`/broker_owners/invite`, {
            broker_id: id,
            email,
          });
        }

        toastr.success(
          "Sukces",
          "Reprezentant jednostki został zaproszony"
        );
        resolve();
      } else {
        throw new Error("Brak uprawnień");
      }
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.message ||
          error?.message ||
          "Wystąpił błąd"
      );
      reject(error);
    }
  });

export const updateOwnerOrganization = ({
  organization_id,
  owner_id,
  assign = false,
}) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(
        `/organizations/${organization_id}`,
        {
          owner_id: assign ? owner_id : null,
        }
      );
      toastr.success(
        "Sukces",
        "Reprezentant jednostki zostal zaktualizowany"
      );
      dispatch(getOwner(owner_id));
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
