import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import {
  Main,
  PageLoader,
  Search,
  Pagination,
  Button,
} from "components";
import { getBrokers } from "actions/brokers";
import InviteBroker from "./components/InviteBroker";

const BrokersList = ({
  history,
  location: { search },
  brokers,
  getBrokers,
}) => {
  const [
    is_open_invite_manager_modal,
    setOpenInviteBrokerModal,
  ] = useState(false);

  const {
    page,
    per_page,
    query: default_query,
  } = queryString.parse(search);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getBrokers({ page, per_page, query });
  }, [page, per_page]);

  if (
    brokers.status === "invalid" ||
    brokers.status === "loading"
  )
    return <PageLoader />;

  if (brokers?.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = brokers;

  return (
    <Main
      breadcrumbs={[{ name: "Brokerzy" }]}
      title="Brokerzy">
      <div className="dashboard">
        {is_open_invite_manager_modal && (
          <InviteBroker
            handleClose={() =>
              setOpenInviteBrokerModal(false)
            }
          />
        )}
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Brokerzy
          </h2>
          <Button
            type="add"
            text="Zaproś"
            onClick={() => setOpenInviteBrokerModal(true)}
          />
          <Button
            style={{ marginLeft: 15 }}
            type="add"
            text="Dodaj"
            onClick={() => history.push("/brokers/new")}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getBrokers({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Imię i nazwisko</th>
                  <th>Adres e-mail</th>
                  <th>Telefon</th>
                  <th>Data utworzenia</th>
                  <th>Konto aktywne</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(
                  ({
                    id,
                    full_name,
                    email,
                    phone,
                    created_at,
                    is_active,
                  }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        history.push(`/brokers/${id}`)
                      }>
                      <td>{full_name}</td>
                      <td>{email}</td>
                      <td>{phone || "-"}</td>
                      <td>
                        {created_at
                          ? moment(created_at).format(
                              "DD.MM.YYYY"
                            )
                          : "brak"}
                      </td>
                      <td>{is_active ? "Tak" : "Nie"}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak brokerów</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ brokers }) => ({ brokers }),
  (dispatch) => ({
    getBrokers: (data) => dispatch(getBrokers(data)),
  })
)(BrokersList);
