const sector_type_options = [
  {
    label: "rolnictwo",
    value: "agriculture",
    sector: "agricultural",
  },
  {
    label: "leśnictwo",
    value: "forestry",
    sector: "agricultural",
  },
  {
    label: "rybołówstwo",
    value: "fishing",
    sector: "agricultural",
  },
  {
    label: "myślistwo",
    value: "hunting",
    sector: "industrial",
  },
  {
    label: "przemysł wydobywczy",
    value: "mining",
    sector: "industrial",
  },
  {
    label: "przemysł przetwórczy",
    value: "processing_industry",
    sector: "service",
  },
  {
    label: "budownictwo",
    value: "construction",
    sector: "service",
  },
  {
    label: "handel",
    value: "commerce",
    sector: "service",
  },
  {
    label: "transport",
    value: "transport",
    sector: "service",
  },
  {
    label: "łączność",
    value: "communications",
    sector: "service",
  },
  {
    label: "gospodarka komunalna",
    value: "utilities",
    sector: "service",
  },
  {
    label: "ochrona zdrowia",
    value: "health_care",
    sector: "service",
  },
  {
    label: "edukacja",
    value: "education",
    sector: "service",
  },
  {
    label: "turystyka",
    value: "tourism",
    sector: "service",
  },
  {
    label: "kultura",
    value: "culture",
    sector: "service",
  },
];

export default sector_type_options;
