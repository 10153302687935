import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  NotFound,
  //
  BrokersList,
  BrokerEdit,
  BrokerCreate,
  //
  OwnersList,
  OwnerCreate,
  OwnerEdit,
  //
  PagesList,
  PageEdit,
  PageNew,
  //
  OrganizationsList,
  OrganizationEdit,
  OrganizationNew,
  //
  CourseNew,
  CourseEdit,
  CoursesList,
  //
  CourseModuleNew,
  CourseModuleEdit,
} from "pages";


const Protected = ({ current_user }) => (
  <Switch>
    <Route
      exact={true}
      path="/"
      component={() => <Redirect to="/organizations" />}
    />

    <Route
      exact={true}
      path="/owners"
      component={OwnersList}
    />
    <Route
      exact={true}
      path="/owners/new"
      component={OwnerCreate}
    />
    <Route
      exact={true}
      path="/owners/:id"
      component={OwnerEdit}
    />

    <Route
      exact={true}
      path="/pages"
      component={PagesList}
    />
    <Route
      exact={true}
      path="/pages/new"
      component={PageNew}
    />
    <Route
      exact={true}
      path="/pages/:id"
      component={PageEdit}
    />
    <Route
      exact={true}
      path="/courses"
      component={CoursesList}
    />
    <Route
      exact={true}
      path="/courses/new"
      component={CourseNew}
    />
    <Route
      exact={true}
      path="/courses/:id"
      component={CourseEdit}
    />
    <Route
      exact={true}
      path="/courses/:id/modules/new"
      component={CourseModuleNew}
    />
    <Route
      exact={true}
      path="/courses/:id/modules/:course_module_id"
      component={CourseModuleEdit}
    />
    <Route
      exact={true}
      path="/organizations"
      component={OrganizationsList}
    />
    <Route
      exact={true}
      path="/organizations/new"
      component={OrganizationNew}
    />
    <Route
      exact={true}
      path="/organizations/:id"
      component={OrganizationEdit}
    />
    {current_user.type === "Admin" && (
      <Switch>
        <Route
          exact={true}
          path="/brokers"
          component={BrokersList}
        />
        <Route
          exact={true}
          path="/brokers/new"
          component={BrokerCreate}
        />
        <Route
          exact={true}
          path="/brokers/:id"
          component={BrokerEdit}
        />
      </Switch>
    )}
    <Route component={NotFound} />
  </Switch>
);

export default connect(({ current_user }) => ({
  current_user,
}))(Protected);
