import { client } from "utils/api";

const getTagsOptions = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/organizations/tags${q ? `?search=${q}` : ""}`
      );

      resolve(
        data.map(({ name }) => ({
          label: name,
          value: name,
        }))
      );
    } catch (error) {
      console.error(error);
      reject([]);
    }
  });

export default getTagsOptions;
