import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ActionBar,
  Button,
  Main,
  ModalDelete,
  PageLoader,
} from "components";
import { Field, reduxForm } from "redux-form";
import { Input } from "components";
import {
  updateOwner,
  getOwner,
  deleteOwner,
  updateOwnerOrganization,
} from "actions/owners";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import AssignOrganization from "./components/AssignOrganization";

const InputField = (props) => <Input {...props} />;

const UpdateOwner = ({
  owner,
  match: {
    params: { id },
  },
  change,
  pristine,
  history,
  getOwner,
  updateOwner,
  updateOwnerOrganization,
  handleSubmit,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  const [
    is_open_add_organization_modal,
    handleOpenAddOrganizationModal,
  ] = useState(false);

  useEffect(() => {
    getOwner(id);
  }, [id]);

  const onSubmit = (values) =>
    updateOwner(id, values).then(() =>
      change("password", "")
    );

  const handleDelete = async () => {
    try {
      await deleteOwner(id);
      history.push("/owners");
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = owner;

  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }
  
  const has_organization = Boolean(data?.organization?.id);

  return (
    <Main
      breadcrumbs={[
        {
          name: "Reprezantanci jednostek",
          href: "/owners",
        },
        { name: data?.full_name },
      ]}
      title={data?.full_name}>
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.full_name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      {is_open_add_organization_modal && (
        <AssignOrganization
          handleClose={() =>
            handleOpenAddOrganizationModal(false)
          }
          handleConfirm={async (organization_id) => {
            await updateOwnerOrganization({
              organization_id,
              owner_id: id,
              assign: true,
            });
            handleOpenAddOrganizationModal(false);
          }}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Field
                    label="Imie"
                    name="first_name"
                    component={InputField}
                  />
                  <Field
                    label="Nazwisko"
                    name="last_name"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Adres e-mail"
                    name="email"
                    component={InputField}
                  />
                  <Field
                    label="Telefon"
                    name="phone"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Zmień hasło"
                    name="password"
                    component={InputField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                {has_organization
                  ? "Jednostka"
                  : "Brak jednostki"}
              </h2>
              {has_organization ? (
                <Button
                  type="delete"
                  text="Usuń"
                  onClick={() =>
                    updateOwnerOrganization({
                      organization_id: data.organization.id,
                      owner_id: id,
                      assign: false,
                    })
                  }
                />
              ) : (
                <Button
                  type="add"
                  text="Dodaj"
                  onClick={() =>
                    handleOpenAddOrganizationModal(true)
                  }
                />
              )}
            </header>
            {has_organization && (
              <div className="dashboard__text">
                <p>Nazwa: <strong>{data.organization.name}</strong></p>
                <p>
                  Publiczna:{" "}
                  {data.organization.is_public
                    ? "Tak"
                    : "Nie"}
                </p>
                <p>
                  Adres: {data.organization.street}{" "}
                  {data.organization.building_number},{" "}
                  {data.organization.city}{" "}
                  {data.organization.post_code}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/owners"
      />
    </Main>
  );
};

const required_fields = [
  "first_name",
  "last_name",
  "email",
];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  if (!values.email) {
    errors.email = "Wymagane";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    )
  ) {
    errors.email = "Niepoprawny adres email";
  }

  return errors;
};

export default connect(
  ({ owner }) => ({
    owner,
    initialValues: {
      first_name: owner?.data?.first_name,
      last_name: owner?.data?.last_name,
      email: owner?.data?.email,
      phone: owner?.data?.phone,
    },
  }),
  (dispatch) => ({
    getOwner: (id) => dispatch(getOwner(id)),
    updateOwner: (id, values) =>
      dispatch(updateOwner(id, values)),
    updateOwnerOrganization: (data) =>
      dispatch(updateOwnerOrganization(data)),
  })
)(
  reduxForm({
    form: "owner_edit",
    enableReinitialize: true,
    validate,
  })(UpdateOwner)
);
