import React, { useState } from "react";

import { AsyncSelect, Button, Modal } from "components";
import getOrganizationsOptions from "utils/options/getOrganizationsOptions";

const AssignOrganization = ({
  handleConfirm,
  handleClose,
}) => {
  const [organization_id, setOrganizationId] = useState(
    null
  );
  return (
    <Modal
      handleClose={handleClose}
      title="Przypisz jednostkę"
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Potwierdź"
            disabled={!organization_id}
            onClick={() => handleConfirm(organization_id)}
          />
        </>
      }>
      <AsyncSelect
        label="Jednostka"
        name="organization_id"
        getData={getOrganizationsOptions}
        onChange={({ value }) => setOrganizationId(value)}
      />
    </Modal>
  );
};

export default AssignOrganization;
