import React from "react";
import DashboardIcon from "./DashboardIcon";
import InfoIcon from "./InfoIcon";
import MovieCategoriesIcon from "./MovieCategoriesIcon";
import ActorCategoriesIcon from "./ActorCategoriesIcon";
import StaticPagesIcon from "./StaticPagesIcon";
import HomeIcon from "./HomeIcon";
import UsersIcon from "./UsersIcon";

export const renderIcons = (icon) => {
  switch (icon) {
    case "users":
      return <UsersIcon />;
    case "dashboard":
      return <DashboardIcon />;
    case "info":
      return <InfoIcon />;
    case "events":
      return <MovieCategoriesIcon />;
    case "offers":
      return <ActorCategoriesIcon />;
    case "static":
      return <StaticPagesIcon />;
    case "home":
      return <HomeIcon />;
    default:
      return null;
  }
};
