import React from "react";
import { ActionBar, Main } from "components";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { Input } from "components";
import { createBroker } from "actions/brokers";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const InputField = (props) => <Input {...props} />;

const required_fields = ["first_name", "last_name"];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  if (!values.email) {
    errors.email = "Wymagane";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    )
  ) {
    errors.email = "Niepoprawny adres email";
  }

  return errors;
};

const CreateBroker = ({
  pristine,
  history,
  handleSubmit,
}) => {
  const onSubmit = (values) =>
    createBroker(values)
      .then((id) => history.push(`/brokers/${id}`))
      .catch((error) => {
        throw new SubmissionError(error);
      });

  return (
    <Main
      breadcrumbs={[
        {
          name: "Brokerzy",
          href: "/brokers",
        },
        { name: "Dodaj brokera" },
      ]}
      title="Dodaj brokera">
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Field
                    label="Imie"
                    name="first_name"
                    component={InputField}
                  />
                  <Field
                    label="Nazwisko"
                    name="last_name"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Adres e-mail"
                    name="email"
                    component={InputField}
                  />
                  <Field
                    label="Telefon"
                    name="phone"
                    component={InputField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/brokers"
      />
    </Main>
  );
};

export default reduxForm({
  form: "broker_create",
  enableReinitialize: true,
  validate,
})(CreateBroker);
