import React from "react";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const DashboardForm = ({ title, buttons, children }) => {
  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          {title}
        </h2>
        {buttons}
      </header>
      <div className="dashboard__form">{children}</div>
    </div>
  );
};

export default DashboardForm;
