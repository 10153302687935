import React from "react";
import { connect } from "react-redux";
import { ActionBar, AsyncSelect, Main } from "components";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { Input } from "components";
import { createOwner } from "actions/owners";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import getOrganizationsOptions from "utils/options/getOrganizationsOptions";
import getBrokersOptions from "utils/options/getBrokersOptions";

const InputField = (props) => <Input {...props} />;
const AsyncSelectField = (props) => (
  <AsyncSelect {...props} />
);
const required_fields = [
  "first_name",
  "last_name",
  "organization_id",
];

const validate = (values) => {
  const errors = {};
  required_fields.forEach((key) => {
    if (!values[key]) {
      errors[key] = "Wymagane";
    }
  });

  if (!values.email) {
    errors.email = "Wymagane";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    )
  ) {
    errors.email = "Niepoprawny adres email";
  }

  return errors;
};

const CreateOwners = ({
  pristine,
  history,
  handleSubmit,
  change,
  current_user,
}) => {
  const onSubmit = (values) =>
    createOwner(values)
      .then((id) => history.push(`/owners/${id}`))
      .catch((error) => {
        throw new SubmissionError(error);
      });

  return (
    <Main
      breadcrumbs={[
        {
          name: "Reprezentanci jednostek",
          href: "/owners",
        },
        { name: "Dodaj reprezentanta jednostki" },
      ]}
      title="Dodaj reprezentanta jednostki">
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe informacje
              </h2>
            </header>
            <div className="dashboard__form">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Field
                    label="Imie"
                    name="first_name"
                    component={InputField}
                  />
                  <Field
                    label="Nazwisko"
                    name="last_name"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Adres e-mail"
                    name="email"
                    component={InputField}
                  />
                  <Field
                    label="Telefon"
                    name="phone"
                    component={InputField}
                  />
                </div>
                <div className="form-row">
                  <Field
                    label="Jednostka"
                    name="organization_id"
                    component={AsyncSelectField}
                    change={change}
                    getData={getOrganizationsOptions}
                  />
                  {current_user?.type === "Admin" && (
                    <Field
                      label="Broker"
                      name="broker_id"
                      component={AsyncSelectField}
                      change={change}
                      getData={getBrokersOptions}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/owners"
      />
    </Main>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(
  reduxForm({
    form: "owner_create",
    enableReinitialize: true,
    validate,
  })(CreateOwners)
);
