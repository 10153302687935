import React, { useState } from "react";

import { Button, Input, Modal } from "components";
import { inviteBroker } from "actions/admin_broker";
import { toastr } from "react-redux-toastr";

const InviteBroker = ({ handleClose }) => {
  const [email, setEmail] = useState("");
  const handleConfirm = async () => {
    try {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          email
        )
      ) {
        toastr.error("Błąd", "Niepoprawny adres email");
        return;
      }

      await inviteBroker({ email });
      handleClose();
    } catch (error) {}
  };
  return (
    <Modal
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      title="Zaproś brokera"
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Potwierdź"
            disabled={!email}
            onClick={handleConfirm}
          />
        </>
      }>
      <Input
        name="email"
        label="Email"
        input={{
          name: "email",
          value: email,
          onChange: ({ target: { value } }) =>
            setEmail(value),
        }}
        type={email}
        meta={{}}
      />
    </Modal>
  );
};

export default InviteBroker;
