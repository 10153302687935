import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  Search,
  Pagination,
  Main,
  PageLoader,
  Button,
} from "components";
import { getOrganizations } from "actions/organizations";
import UploadModal from "./components/UploadModal";

const OrganizationsList = ({
  history,
  location: { search },
  organizations,
  getOrganizations,
}) => {
  const {
    page,
    per_page,
    query: default_query,
  } = queryString.parse(search);
  const [
    is_open_upload_modal,
    setOpenUploadModal,
  ] = useState(false);

  const [query, setQuery] = useState(default_query || "");

  useEffect(() => {
    getOrganizations({ page, per_page, query });
  }, [page, per_page]);

  if (["invalid", "loading"].includes(organizations.status))
    return <PageLoader />;

  if (organizations.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = organizations;

  return (
    <Main
      breadcrumbs={[{ name: "Organizacje" }]}
      title="Organizacje">
      {is_open_upload_modal && (
        <UploadModal
          handleClose={() => setOpenUploadModal(false)}
        />
      )}
      <div className="dashboard">
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            Zarządzanie organizacjami
          </h2>
          <Button
            style={{ marginRight: 20 }}
            type="add"
            text="Dodaj"
            onClick={() =>
              history.push("/organizations/new")
            }
          />
          <Button
            type="add"
            text="Prześlij (CSV)"
            onClick={() => setOpenUploadModal(true)}
          />
        </header>
        <Search
          action={(page, per_page, query) => {
            setQuery(query);
            getOrganizations({
              page,
              per_page,
              query,
              loading: false,
            });
          }}
          defaultValue={query}
        />
        {data?.length > 0 ? (
          <>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th width="300">Nazwa</th>
                  <th width="200">Slug</th>
                  <th width="300">
                    Reprezentant jednostki
                  </th>
                  <th>Tagi</th>
                  <th>Publiczna</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data.map(
                  ({
                    id,
                    name,
                    slug,
                    owner,
                    tag_list,
                    is_public,
                  }) => (
                    <tr
                      key={id}
                      onClick={() =>
                        history.push(`/organizations/${id}`)
                      }>
                      <td>{name}</td>
                      <td>{slug}</td>
                      <td>
                        {owner?.id
                          ? `${owner.first_name} ${owner.last_name}`
                          : "-"}
                      </td>
                      <td>{tag_list.join(", ") || "-"}</td>
                      <td>{is_public ? "Tak" : "Nie"}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak organizacji</p>
          </div>
        )}
        <Pagination data={{ ...meta }} />
      </div>
    </Main>
  );
};

export default connect(
  ({ organizations }) => ({ organizations }),
  (dispatch) => ({
    getOrganizations: (data) =>
      dispatch(getOrganizations(data)),
  })
)(OrganizationsList);
