import React, { useState } from "react";

import { AsyncSelect, Button, Modal } from "components";
import getOwnersOptions from "utils/options/getOwnersOptions";

const AssignOwner = ({
  handleConfirm,
  handleClose,
}) => {
  const [owner_id, setOwnerId] = useState(
    null
  );
  return (
    <Modal
      handleClose={handleClose}
      title="Przypisz reprezentanta jednostki"
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Potwierdź"
            disabled={!owner_id}
            onClick={() => handleConfirm(owner_id)}
          />
        </>
      }>
      <AsyncSelect
        label="Reprezentant jednostki"
        name="owner_id"
        getData={getOwnersOptions}
        onChange={({ value }) => setOwnerId(value)}
      />
    </Modal>
  );
};

export default AssignOwner;
