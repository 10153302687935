const province_options = [
  {
    label: "dolnośląskie",
    value: "dolnośląskie",
  },
  {
    label: "kujawsko-pomorskie",
    value: "kujawsko-pomorskie",
  },
  {
    label: "lubelskie",
    value: "lubelskie",
  },
  {
    label: "lubuskie",
    value: "lubuskie",
  },
  {
    label: "łódzkie",
    value: "łódzkie",
  },
  {
    label: "małopolskie",
    value: "małopolskie",
  },
  {
    label: "mazowieckie",
    value: "mazowieckie",
  },
  {
    label: "opolskie",
    value: "opolskie",
  },
  {
    label: "podkarpackie",
    value: "podkarpackie",
  },
  {
    label: "podlaskie",
    value: "podlaskie",
  },
  {
    label: "pomorskie",
    value: "pomorskie",
  },
  {
    label: "śląskie",
    value: "śląskie",
  },
  {
    label: "świętokrzyskie",
    value: "świętokrzyskie",
  },
  {
    label: "warmińsko-mazurskie",
    value: "warmińsko-mazurskie",
  },
  {
    label: "wielkopolskie",
    value: "wielkopolskie",
  },
  {
    label: "zachodniopomorskie",
    value: "zachodniopomorskie",
  },
];

export default province_options;