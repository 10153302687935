import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Button, ImageUploader } from "components";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import {
  updateOrganizationImage,
  deleteOrganizationImage,
} from "actions/organizations";

const Image = ({
  organization,
  change,
  pristine,
  handleSubmit,
  updateOrganizationImage,
  deleteOrganizationImage,
}) => {
  const has_logo = Boolean(organization.logo_url);

  const onSubmit = ({ logo }) =>
    updateOrganizationImage(organization.id, logo);

  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">Logo</h2>
        {has_logo ? (
          <Button
            type="delete"
            text="Usuń"
            onClick={() =>
              deleteOrganizationImage(organization.id)
            }
          />
        ) : (
          <Button
            type="save"
            disabled={pristine}
            text="Zapisz"
            onClick={handleSubmit(onSubmit)}
          />
        )}
      </header>
      <div className="dashboard__image">
        {has_logo ? (
          <img src={organization.logo_url} alt="" />
        ) : (
          <ImageUploader
            handleFile={(file) => change("logo", file)}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  ({ organization }) => ({
    organization: organization.data,
    initialValues: {
      logo: null,
    },
  }),
  (dispatch) => ({
    updateOrganizationImage: (id, logo) =>
      dispatch(updateOrganizationImage(id, logo)),
    deleteOrganizationImage: (id) =>
      dispatch(deleteOrganizationImage(id)),
  })
)(
  reduxForm({
    form: "organization_edit_image",
    enableReinitialize: true,
  })(Image)
);
