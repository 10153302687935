import React, { useState } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import { Button, Input, Modal } from "components";
import { inviteOwner } from "actions/owners";

const InviteOwner = ({ inviteOwner, handleClose }) => {
  const [email, setEmail] = useState("");
  const handleConfirm = async () => {
    try {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          email
        )
      ) {
        toastr.error("Błąd", "Niepoprawny adres email");
        return;
      }

      await inviteOwner(email);
      handleClose();
    } catch (error) {}
  };
  return (
    <Modal
      handleClose={handleClose}
      title="Zaproś reprezentanta jednostki"
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Potwierdź"
            disabled={!email}
            onClick={handleConfirm}
          />
        </>
      }>
      <Input
        name="email"
        label="Email"
        input={{
          name: "email",
          value: email,
          onChange: ({ target: { value } }) =>
            setEmail(value),
        }}
        type={email}
        meta={{}}
      />
    </Modal>
  );
};

export default connect(
  ({ owners }) => ({ owners }),
  (dispatch) => ({
    inviteOwner: (email) => dispatch(inviteOwner(email)),
  })
)(InviteOwner);
