import { client } from "utils/api";

const getBrokersOptions = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/brokers/list?per_page=50${q ? `&search=${q}` : ""}`
      );

      resolve(
        data.map(({ id, full_name }) => ({
          label: full_name,
          value: id,
        }))
      );
    } catch (error) {
      console.error(error);
      reject([]);
    }
  });

export default getBrokersOptions;
