import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import {
  Main,
  Input,
  PageLoader,
  Checkbox,
  Select,
  AsyncSelect,
  AsyncCreatableSelect,
  DashboardForm,
  ActionBar,
  ModalDelete,
} from "components";
import {
  updateOrganization,
  getOrganization,
  deleteOrganization,
} from "actions/organizations";

import Editor from "components/EditorSimple";
import getOwnersOptions from "utils/options/getOwnersOptions";
import province_options from "utils/options/province_options";
import organization_type_options from "utils/options/organization_type_options";
import sector_type_options from "utils/options/sector_type_options ";
import company_sector_options from "utils/options/company_sector_options";
import college_type_options from "utils/options/college_type_options";
import getTagsOptions from "utils/options/getTagsOptions.js";
import Image from "./components/Image";

const selector = formValueSelector("update_organization");

const InputField = (props) => <Input {...props} />;
const CheckboxField = (props) => <Checkbox {...props} />;
const AsyncSelectField = (props) => (
  <AsyncSelect {...props} />
);
const SelectField = (props) => <Select {...props} />;
const AsyncCreatableSelectField = (props) => (
  <AsyncCreatableSelect {...props} />
);

const EditOrganization = ({
  organization,
  match: {
    params: { id },
  },
  change,
  pristine,
  form_values,
  history,
  getOrganization,
  updateOrganization,
  handleSubmit,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getOrganization(id);
  }, [id]);

  const onSubmit = async (values) => {
    const data_to_send = { ...values };
    data_to_send.tag_list = data_to_send.tag_list.join(",");
    try {
      await updateOrganization(id, data_to_send);
    } catch (error) {
      throw new SubmissionError(error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteOrganization(id);
      history.push("/organizations");
    } catch (error) {
      console.log(error);
    }
  };

  const { status, data } = organization;

  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }

  if (status === "failure") {
    return <p>Error</p>;
  }

  return (
    <Main
      breadcrumbs={[
        { name: "Organizacje", href: "/organizations" },
        { name: data?.name },
      ]}
      title={data?.name}>
      {is_open_delete_modal && (
        <ModalDelete
          name={data?.name}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={handleDelete}
        />
      )}
      <form noValidate>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <DashboardForm title="Podstawowe informacje">
              <div className="form-row">
                <Select
                  label="Typ"
                  isDisabled={true}
                  options={organization_type_options}
                  defaultValue={organization_type_options.find(
                    ({ value }) => data?.type === value
                  )}
                />
              </div>
              {data.type === "Organization::College" && (
                <div className="form-row">
                  <Field
                    label="Typ uczelni"
                    name="college_type"
                    component={SelectField}
                    options={college_type_options}
                    handleChange={(value) =>
                      change("college_type", value)
                    }
                    defaultValue={college_type_options.find(
                      ({ value }) =>
                        data?.college_type === value
                    )}
                  />
                </div>
              )}
              {data.type === "Organization::Company" && (
                <div className="form-row">
                  <Field
                    label="Sektor"
                    name="company_sector"
                    component={SelectField}
                    options={company_sector_options}
                    handleChange={(value) => {
                      change("sector_type", null);
                      change("company_sector", value);
                    }}
                    defaultValue={company_sector_options.find(
                      ({ value }) =>
                        data?.company_sector === value
                    )}
                  />
                  <Field
                    label="Typ sektora"
                    name="sector_type"
                    key={form_values.company_sector}
                    component={SelectField}
                    options={sector_type_options.filter(
                      ({ sector }) =>
                        sector ===
                        form_values.company_sector
                    )}
                    handleChange={(value) =>
                      change("sector_type", value)
                    }
                    defaultValue={sector_type_options.find(
                      ({ value }) =>
                        data?.sector_type === value
                    )}
                  />
                </div>
              )}
              <div className="form-row">
                <Field
                  label="Nazwa"
                  name="name"
                  component={InputField}
                />
                <Field
                  label="Reprezentant jednostki"
                  name="owner_id"
                  component={AsyncSelectField}
                  change={change}
                  getData={getOwnersOptions}
                  initValue={
                    data?.owner?.id
                      ? {
                          label: `${data.owner.first_name} ${data.owner.last_name}`,
                          value: data.owner.id,
                        }
                      : null
                  }
                />
              </div>
              <Field
                label="Tagi"
                name="tag_list"
                multi={true}
                component={AsyncCreatableSelectField}
                change={change}
                getData={getTagsOptions}
                initValue={data.tag_list.map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
              <Field
                label="Strona internetowa"
                name="website_url"
                component={InputField}
              />
              <Field
                label="Publiczny?"
                name="is_public"
                component={CheckboxField}
              />
            </DashboardForm>
            <DashboardForm title=" Oferta technologiczna Uczelni i usługi badawcze">
              <Editor
                name="technological_offer_research_services"
                change={change}
                value={
                  data?.technological_offer_research_services
                }
              />
            </DashboardForm>
            <DashboardForm title="Rozwój kariery naukowej / stypendia">
              <Editor
                name="research_career_development"
                change={change}
                value={data?.research_career_development}
              />
            </DashboardForm>
            <DashboardForm title="Wsparcie innowacji w firmie">
              <Editor
                name="innovation_support"
                change={change}
                value={data?.innovation_support}
              />
            </DashboardForm>
            <DashboardForm title="Fundusze europejskie dla MŚP">
              <Editor
                name="european_funds"
                change={change}
                value={data?.european_funds}
              />
            </DashboardForm>
            <DashboardForm title="Wsparcie badań rozwojowych na Uczelni">
              <Editor
                name="support_for_developmental_research"
                change={change}
                value={
                  data?.support_for_developmental_research
                }
              />
            </DashboardForm>
          </div>
          <div className="column-half">
            <Image />
            <DashboardForm title="Adres">
              <div className="form-row">
                <Field
                  label="Ulica"
                  name="street"
                  component={InputField}
                />
                <Field
                  label="Numer budynku"
                  name="building_number"
                  component={InputField}
                />
              </div>
              <div className="form-row">
                <Field
                  label="Miasto"
                  name="city"
                  component={InputField}
                />
                <Field
                  label="Kod pocztowy"
                  name="post_code"
                  component={InputField}
                />
              </div>
              <div className="form-row">
                <Field
                  label="Województwo"
                  name="province"
                  component={SelectField}
                  options={province_options}
                  handleChange={(value) =>
                    change("province", value)
                  }
                  defaultValue={province_options?.find(
                    ({ value }) => value === data.province
                  )}
                />
              </div>
            </DashboardForm>
            <DashboardForm title="Osoba kontaktowa">
              <Field
                label="Imię i nazwisko"
                name="contact_person_name"
                component={InputField}
              />
              <div className="form-row">
                <Field
                  label="E-mail"
                  name="contact_person_email"
                  component={InputField}
                />
                <Field
                  label="Telefon"
                  name="contact_person_phone"
                  component={InputField}
                />
              </div>
            </DashboardForm>
            <DashboardForm title="Opinie o innowacyjności">
              <Editor
                name="innovation_opinions"
                change={change}
                value={data?.innovation_opinions}
              />
            </DashboardForm>
            <DashboardForm title="Wejście firmy na rynki zagraniczne">
              <Editor
                name="foreign_markets_entry"
                change={change}
                value={data?.foreign_markets_entry}
              />
            </DashboardForm>
            <DashboardForm title="Transfer wyników badań powstałych na Uczelni">
              <Editor
                name="research_transfer"
                change={change}
                value={data?.research_transfer}
              />
            </DashboardForm>
            <DashboardForm title="Analizy rynku">
              <Editor
                name="market_analyses"
                change={change}
                value={data?.market_analyses}
              />
            </DashboardForm>
            <DashboardForm title="Inne">
              <Editor
                name="other_info"
                change={change}
                value={data?.other_info}
              />
            </DashboardForm>
          </div>
        </div>
      </form>
      <ActionBar
        deleteAction={() => handleOpenDeleteModal(true)}
        saveAction={handleSubmit(onSubmit)}
        disable_save={pristine}
        cancel_path="/organizations"
      />
    </Main>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Wymagane";
  }

  if (values.type === "Organization::College") {
    if (!values.college_type) {
      errors.college_type = "Wymagane";
    }
  }

  if (values.type === "Organization::Company") {
    if (!values.company_sector) {
      errors.company_sector = "Wymagane";
    }
    if (!values.sector_type) {
      errors.sector_type = "Wymagane";
    }
  }

  return errors;
};

export default connect(
  (state) => ({
    organization: state.organization,
    initialValues: {
      name: state.organization.data?.name,
      is_public: state.organization.data?.is_public,
      company_sector:
        state.organization.data?.company_sector || null,
      sector_type:
        state.organization.data?.sector_type || null,
      college_type:
        state.organization.data?.college_type || null,
      owner_id: state.organization.data?.owner?.id || null,
      street: state.organization.data?.street || null,
      building_number:
        state.organization.data?.building_number || null,
      city: state.organization.data?.city || null,
      province: state.organization.data?.province || null,
      post_code: state.organization.data?.post_code || null,
      contact_person_name:
        state.organization.data?.contact_person_name ||
        null,
      contact_person_email:
        state.organization.data?.contact_person_email ||
        null,
      contact_person_phone:
        state.organization.data?.contact_person_phone ||
        null,
      website_url:
        state.organization.data?.website_url || null,
      innovation_opinions:
        state.organization.data?.innovation_opinions ||
        null,
      technological_offer_research_services:
        state.organization.data
          ?.technological_offer_research_services || null,
      research_career_development:
        state.organization.data
          ?.research_career_development || null,
      innovation_support:
        state.organization.data?.innovation_support || null,
      foreign_markets_entry:
        state.organization.data?.foreign_markets_entry ||
        null,
      research_transfer:
        state.organization.data?.research_transfer || null,
      european_funds:
        state.organization.data?.european_funds || null,
      support_for_developmental_research:
        state.organization.data
          ?.support_for_developmental_research || null,
      market_analyses:
        state.organization.data?.market_analyses || null,
      other_info:
        state.organization.data?.other_info || null,
      tag_list: state.organization.data?.tag_list || [],
    },
    form_values: {
      name: selector(state, "name"),
      type: selector(state, "type"),
      owner_id: selector(state, "owner_id"),
      company_sector: selector(state, "company_sector"),
      sector_type: selector(state, "sector_type"),
    },
  }),
  (dispatch) => ({
    updateOrganization: (values, id) =>
      dispatch(updateOrganization(values, id)),
    getOrganization: (id) => dispatch(getOrganization(id)),
  })
)(
  reduxForm({
    form: "update_organization",
    enableReinitialize: true,
    validate,
  })(EditOrganization)
);
