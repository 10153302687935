import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getBrokers = ({
  page = 1,
  per_page = 10,
  loading = true,
  query,
}) => async (dispatch) => {
  try {
    loading && dispatch({ type: "BROKERS_LOADING" });

    const { data, meta } = await client.get(
      `/brokers?sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&search=${query}` : ""
      }`
    );
    dispatch({
      type: "BROKERS_SUCCESS",
      payload: { data, meta },
    });
  } catch (error) {
    dispatch({ type: "BROKERS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const getBroker = (id, load = true) => async (
  dispatch
) => {
  try {
    if (load) {
      dispatch({ type: "BROKER_LOADING" });
    }

    const { data } = await client.get(`/brokers/${id}`);

    dispatch({
      type: "BROKER_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "BROKER_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createBroker = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(
        "/brokers",
        values
      );
      toastr.success("Sukces", "Broker zostal dodany");
      resolve(data.id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const updateBroker = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(
        `/brokers/${id}`,
        values
      );
      toastr.success(
        "Sukces",
        "Broker zostal zaktualizowany"
      );
      dispatch({
        type: "BROKER_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteBroker = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/brokers/${id}`);
      toastr.success("Sukces", "Broker został usunięty");
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd.");
      reject(error);
    }
  });

export const createBrokerOrganization = ({
  broker_id,
  organization_id,
}) => async (dispatch) => {
  try {
    await client.post("/broker_organizations", {
      broker_id,
      organization_id,
    });
    toastr.success(
      "Sukces",
      "Organizacja została przypisana"
    );
    dispatch(getBroker(broker_id, false));
  } catch (error) {
    const message =
      error?.response?.data?.errors?.[0]?.source?.pointer ===
      "/data/attributes/organization_id"
        ? "Broker jest już przypisany do tej organizacji"
        : "Wystąpił błąd";
    toastr.error("Błąd", message);
  }
};

export const deleteBrokerOrganization = ({
  broker_id,
  broker_organization_id,
}) => async (dispatch) => {
  try {
    await client.delete(
      `/broker_organizations/${broker_organization_id}`
    );
    toastr.success(
      "Sukces",
      "Organizacja została usunięta"
    );
    dispatch(getBroker(broker_id, false));
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const createBrokerOwner = ({
  broker_id,
  owner_id,
}) => async (dispatch) => {
  try {
    await client.post("/broker_owners", {
      broker_id,
      owner_id,
    });
    toastr.success(
      "Sukces",
      "Reprezentant został przypisany"
    );
    dispatch(getBroker(broker_id, false));
  } catch (error) {
    const message =
      error?.response?.data?.errors?.[0]?.source?.pointer ===
      "/data/attributes/owner_id"
        ? "Reprezentant jest już przypisany do tego brokera"
        : "Wystąpił błąd";
    toastr.error("Błąd", message);
  }
};

export const deleteBrokerOwner = ({
  broker_id,
  broker_owner_id,
}) => async (dispatch) => {
  try {
    await client.delete(
      `/broker_owners/${broker_owner_id}`
    );
    toastr.success(
      "Sukces",
      "Reprezentant został usunięty"
    );
    dispatch(getBroker(broker_id, false));
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};
