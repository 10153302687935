import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getOrganizations = ({
  page = 1,
  per_page = 10,
  loading = true,
  query,
}) => async (dispatch) => {
  try {
    loading && dispatch({ type: "ORGANIZATIONS_LOADING" });

    const { data, meta } = await client.get(
      `/organizations?sort[order]=asc&sort[column]=id&page=${page}&per_page=${per_page}${
        query ? `&search=${query}` : ""
      }`
    );
    dispatch({
      type: "ORGANIZATIONS_SUCCESS",
      payload: { data, meta },
    });
  } catch (error) {
    dispatch({ type: "ORGANIZATIONS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const getOrganization = (id) => async (dispatch) => {
  try {
    dispatch({ type: "ORGANIZATION_LOADING" });
    const { data } = await client.get(
      `/organizations/${id}`
    );

    dispatch({
      type: "ORGANIZATION_SUCCESS",
      payload: { data },
    });
  } catch (error) {
    dispatch({ type: "ORGANIZATION_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createOrganization = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const form_data = new FormData();
      Object.keys(values).forEach((key) => {
        form_data.append(key, values[key]);
      });

      const { data } = await client.post(
        "/organizations",
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toastr.success(
        "Sukces",
        "Organizacja została dodana"
      );

      resolve(data);
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const updateOrganization = (id, values) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(
        `/organizations/${id}`,
        values
      );
      toastr.success(
        "Sukces",
        "Organizacja została zaktualizowana"
      );
      dispatch({
        type: "ORGANIZATION_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const deleteOrganization = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/organizations/${id}`);
      toastr.success(
        "Sukces",
        "Organizacja została usunięta"
      );
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd.");
      reject(error);
    }
  });

export const updateOrganizationImage = (id, logo) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      const form_data = new FormData();
      form_data.append("logo", logo);

      const { data } = await client.put(
        `/organizations/${id}`,
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toastr.success(
        "Sukces",
        "Organizacja została zaktualizowana"
      );
      dispatch({
        type: "ORGANIZATION_SUCCESS",
        payload: { data },
      });
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteOrganizationImage = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.put(`/organizations/${id}`, {
        logo: null,
      });
      toastr.success(
        "Sukces",
        "Zdjęcie zostało usunięte"
      );
      dispatch({
        type: "ORGANIZATION_SUCCESS",
        payload: { data },
      });
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(error);
    }
  });

export const uploadCsv = async (file) => {
  try {
    const form_data = new FormData();
    form_data.append("file", file);

    await client.post("/organizations/upload", form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toastr.success(
      "Sukces",
      "Organizacje zostały utworzone"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error || "Wystąpił błąd"
    );
    throw error;
  }
};
