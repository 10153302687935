import React from "react";
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import { connect } from "react-redux";
import {
  Main,
  Input,
  Checkbox,
  Select,
  AsyncSelect,
  AsyncCreatableSelect,
  DashboardForm,
  ActionBar,
  ImageUploader,
} from "components";
import { createOrganization } from "actions/organizations";

import Editor from "components/EditorSimple";
import getOwnersOptions from "utils/options/getOwnersOptions";
import province_options from "utils/options/province_options";
import organization_type_options from "utils/options/organization_type_options";
import sector_type_options from "utils/options/sector_type_options ";
import company_sector_options from "utils/options/company_sector_options";
import college_type_options from "utils/options/college_type_options";
import getTagsOptions from "utils/options/getTagsOptions.js";
import { ReactComponent as InfoIcon } from "icons/info.svg";

const selector = formValueSelector("create_organization");

const InputField = (props) => <Input {...props} />;
const CheckboxField = (props) => <Checkbox {...props} />;
const AsyncSelectField = (props) => (
  <AsyncSelect {...props} />
);
const SelectField = (props) => <Select {...props} />;
const AsyncCreatableSelectField = (props) => (
  <AsyncCreatableSelect {...props} />
);

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Wymagane";
  }

  if (values.type === "Organization::College") {
    if (!values.college_type) {
      errors.college_type = "Wymagane";
    }
  }

  if (values.type === "Organization::Company") {
    if (!values.company_sector) {
      errors.company_sector = "Wymagane";
    }
    if (!values.sector_type) {
      errors.sector_type = "Wymagane";
    }
  }

  return errors;
};

const NewOrganization = ({
  handleSubmit,
  history,
  change,
  form_values,
}) => {
  const onSubmit = async (values) => {
    const data_to_send = { ...values };
    data_to_send.tag_list = data_to_send.tag_list.join(",");
    try {
      const { id } = await createOrganization(data_to_send);
      history.push(`/organizations/${id}`);
    } catch (error) {
      throw new SubmissionError(error);
    }
  };

  return (
    <Main
      breadcrumbs={[
        { name: "Organizacje", href: "/organizations" },
        { name: "Dodaj organizację" },
      ]}
      title="Dodaj organizację">
      <form noValidate>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <DashboardForm title="Podstawowe informacje">
              <div className="form-row">
                <Field
                  key={form_values.type}
                  label="Typ"
                  name="type"
                  component={SelectField}
                  options={organization_type_options}
                  handleChange={(value) =>
                    change("type", value)
                  }
                  defaultValue={organization_type_options.find(
                    ({ value }) =>
                      form_values.type === value
                  )}
                />
              </div>
              {form_values.type ===
                "Organization::College" && (
                <div className="form-row">
                  <Field
                    label="Typ uczelni"
                    name="college_type"
                    component={SelectField}
                    options={college_type_options}
                    handleChange={(value) =>
                      change("college_type", value)
                    }
                  />
                </div>
              )}
              {form_values.type ===
                "Organization::Company" && (
                <div className="form-row">
                  <Field
                    label="Sektor"
                    name="company_sector"
                    component={SelectField}
                    options={company_sector_options}
                    handleChange={(value) => {
                      change("sector_type", null);
                      change("company_sector", value);
                    }}
                  />
                  <Field
                    label="Typ sektora"
                    name="sector_type"
                    key={form_values.company_sector}
                    component={SelectField}
                    options={sector_type_options.filter(
                      ({ sector }) =>
                        sector ===
                        form_values.company_sector
                    )}
                    handleChange={(value) =>
                      change("sector_type", value)
                    }
                  />
                </div>
              )}
              <div className="form-row">
                <Field
                  label="Nazwa"
                  name="name"
                  component={InputField}
                />
                <Field
                  label="Reprezentant jednostki"
                  name="owner_id"
                  component={AsyncSelectField}
                  change={change}
                  getData={getOwnersOptions}
                />
              </div>
              <Field
                label="Tagi"
                name="tag_list"
                multi={true}
                component={AsyncCreatableSelectField}
                change={change}
                getData={getTagsOptions}
              />
              <Field
                label="Strona internetowa"
                name="website_url"
                component={InputField}
              />
              <Field
                label="Publiczny?"
                name="is_public"
                component={CheckboxField}
              />
            </DashboardForm>
            <DashboardForm title=" Oferta technologiczna Uczelni i usługi badawcze">
              <Editor
                name="technological_offer_research_services"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Rozwój kariery naukowej / stypendia">
              <Editor
                name="research_career_development"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Wsparcie innowacji w firmie">
              <Editor
                name="innovation_support"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Fundusze europejskie dla MŚP">
              <Editor
                name="european_funds"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Wsparcie badań rozwojowych na Uczelni">
              <Editor
                name="support_for_developmental_research"
                change={change}
                value=""
              />
            </DashboardForm>
          </div>
          <div className="column-half">
            <div className="dashboard">
              <header className="dashboard__header">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">
                  Logo
                </h2>
              </header>
              <div className="dashboard__form">
                <ImageUploader
                  handleFile={(file) =>
                    change("logo", file)
                  }
                />
              </div>
            </div>
            <DashboardForm title="Adres">
              <div className="form-row">
                <Field
                  label="Ulica"
                  name="street"
                  component={InputField}
                />
                <Field
                  label="Numer budynku"
                  name="building_number"
                  component={InputField}
                />
              </div>
              <div className="form-row">
                <Field
                  label="Miasto"
                  name="city"
                  component={InputField}
                />
                <Field
                  label="Kod pocztowy"
                  name="post_code"
                  component={InputField}
                />
              </div>
              <div className="form-row">
                <Field
                  label="Województwo"
                  name="province"
                  component={SelectField}
                  options={province_options}
                  handleChange={(value) =>
                    change("province", value)
                  }
                />
              </div>
            </DashboardForm>
            <DashboardForm title="Osoba kontaktowa">
              <Field
                label="Imię i nazwisko"
                name="contact_person_name"
                component={InputField}
              />
              <div className="form-row">
                <Field
                  label="E-mail"
                  name="contact_person_email"
                  component={InputField}
                />
                <Field
                  label="Telefon"
                  name="contact_person_phone"
                  component={InputField}
                />
              </div>
            </DashboardForm>
            <DashboardForm title="Opinie o innowacyjności">
              <Editor
                name="innovation_opinions"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Wejście firmy na rynki zagraniczne">
              <Editor
                name="foreign_markets_entry"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Transfer wyników badań powstałych na Uczelni">
              <Editor
                name="research_transfer"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Analizy rynku">
              <Editor
                name="market_analyses"
                change={change}
                value=""
              />
            </DashboardForm>
            <DashboardForm title="Inne">
              <Editor
                name="other_info"
                change={change}
                value=""
              />
            </DashboardForm>
          </div>
        </div>
      </form>
      <ActionBar
        saveAction={handleSubmit(onSubmit)}
        cancel_path="/organizations"
      />
    </Main>
  );
};

export default connect((state) => ({
  initialValues: {
    is_public: false,
    name: "",
    type: "Organization::College",
    tag_list: [],
  },
  form_values: {
    name: selector(state, "name"),
    type: selector(state, "type"),
    owner_id: selector(state, "owner_id"),
    company_sector: selector(state, "company_sector"),
    sector_type: selector(state, "sector_type"),
  },
}))(
  reduxForm({
    form: "create_organization",
    enableReinitialize: true,
    validate,
  })(NewOrganization)
);
